import * as React from "react";
import { useEffect } from "react";
import { Seo } from "../components/";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

const NotFound = () => {
  const location = useLocation();
  const ecpKey = location.pathname.replace("/", "");

  useEffect(() => {
    let ecpDiscovered = false;
    if (ecpKey) {
      if (ecpKey.match(/^\d{4}(?:-)/)) {
        ecpDiscovered = true;
        navigate("/");
      }
    }
    if (!ecpDiscovered) {
      navigate("/403");
    }
  }, [location]);

  return (
    <>
      <Seo title="Finding Page" />
    </>
  );
};

export default NotFound;
